<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <div class="form-group">
      <label>Type <span class="text-danger">*</span></label>
      <Field
        @change="onChange($event)"
        name="professional"
        as="select"
        class="form-control"
      >
        <option class="d-none" value="" disabled>Particulier</option>
        <option value="Y">Professionnel</option>
        <option value="N">Particulier</option>
      </Field>
    </div>
    <div class="form-group" v-if="professional === 'Y'">
      <label> Raison sociale <span class="text-danger">*</span></label>

      <Field name="name" v-slot="{ meta, field }">
        <input
          :class="{ 'is-invalid': meta.touched && !meta.valid }"
          v-bind="field"
          type="text"
          class="form-control"
          placeholder="Raison sociale"
        />
      </Field>
      <span class="text-danger d-block"><ErrorMessage name="name" /></span>
    </div>

    <div v-if="professional === 'N'" class="form-group">
      <div class="row">
        <div class="col-6">
          <label>Nom <span class="text-danger">*</span> </label>
          <Field name="lastname" v-slot="{ meta, field }">
            <input
              :class="{ 'is-invalid': meta.touched && !meta.valid }"
              v-bind="field"
              type="text"
              class="form-control"
              placeholder="Nom"
            />
          </Field>
          <span class="text-danger d-block"
            ><ErrorMessage name="lastname"
          /></span>
        </div>
        <div class="col-6">
          <label>Prénom <span class="text-danger">*</span> </label>
          <Field name="firstname" v-slot="{ meta, field }">
            <input
              :class="{ 'is-invalid': meta.touched && !meta.valid }"
              v-bind="field"
              type="text"
              class="form-control"
              placeholder="Prénom"
            />
          </Field>
          <span class="text-danger d-block"
            ><ErrorMessage name="firstname"
          /></span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label label>Email</label>
      <Field name="email" v-slot="{ meta, field }">
        <input
          :class="{ 'is-invalid': meta.touched && !meta.valid }"
          v-bind="field"
          type="email"
          class="form-control"
          placeholder="Adresse email..."
        />
      </Field>
      <span class="text-danger d-block"><ErrorMessage name="email" /></span>
    </div>

    <div class="form-group">
      <label>Téléphone</label>
      <Field name="mobile" v-slot="{ meta, field }">
        <input
          :class="{ 'is-invalid': meta.touched && !meta.valid }"
          v-bind="field"
          class="form-control"
          placeholder="Téléphone"
        />
      </Field>
      <span class="text-danger d-block"><ErrorMessage name="mobile" /></span>
    </div>
    <div class="form-group">
      <label>Adresse</label>
      <Field name="billing_address" v-slot="{ meta, field }">
        <input
          :class="{ 'is-invalid': meta.touched && !meta.valid }"
          v-bind="field"
          class="form-control"
          placeholder="Adresse"
        />
      </Field>
      <span class="text-danger d-block"
        ><ErrorMessage name="billing_address"
      /></span>
    </div>
    <div class="form-group">
      <label label>Ville</label>
      <Field name="billing_city" v-slot="{ meta, field }">
        <input
          :class="{ 'is-invalid': meta.touched && !meta.valid }"
          v-bind="field"
          type="text"
          class="form-control"
          placeholder="Ville"
        />
      </Field>
      <span class="text-danger d-block"
        ><ErrorMessage name="billing_city"
      /></span>
    </div>
    <div class="form-group" v-if="countries">
      <label label>Pays</label>
      <Field name="billing_country" as="select" class="form-control">
        <option value="" disabled>Séléctionner un pays</option>
        <option :value="country" v-for="country in countries" :key="country.id">
          {{ country }}
        </option>
      </Field>
      <span class="text-danger d-block"
        ><ErrorMessage name="billing_country"
      /></span>
    </div>

    <br />
    <div class="form-group float-right ml-2 d-inline">
      <button class="btn btn-primary btn-md" :disabled="customer_submission">
        <i class="fas fa-save"></i> Enregistrer
      </button>
    </div>
  </Form>
</template>

<script>
import SettingsService from "@/services/settings.service";
import CustomerService from "@/services/customer.service";

const settingsService = new SettingsService();
const customerService = new CustomerService();

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "AddCustomer",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object({
      professional: yup.string().default("N"),
      email: yup.string().email("email doit être valide"),
      firstname: yup.string().when("professional", {
        is: "N",
        then: yup.string().required("Ce champ est obligatoire"),
      }),
      lastname: yup.string().when("professional", {
        is: "N",
        then: yup.string().required("Ce champ est obligatoire"),
      }),
      name: yup.string().when("professional", {
        is: "Y",
        then: yup.string().required("Ce champ est obligatoire"),
      }),
      billing_address: yup.string(),
      billing_ville: yup.string(),
      billing_country: yup.string(),
    });
    return {
      professional: "N",
      countries: [],
      showName: true,
      schema,
      customer_submission: false,
    };
  },
  created() {
    this.getCountries();
  },
  methods: {
    async getCountries() {
      try {
        const countries = await settingsService.getCountries();
        this.countries = Object.values(countries.data);
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit(values, { resetForm }) {
      this.customer_submission = true;
      let customer = values;
      if (this.professional == "N") {
        let name = values.firstname + " " + values.lastname;
        customer = _.omit(values, ["firstname", "lastname"]);
        customer = { ...customer, name: name, professional: "N" };
      }
      customer = { ...customer, type: "customer" };
      try {
        const res = await customerService.store(customer);
        if (res.data.status == "success") {
          this.$toast.success("Client enregistré", {
            position: "top-right",
            timeout: 5000,
          });
          this.$parent.$options.methods.closeModal();
          resetForm();
          this.$emit("customerId", res.data.data);
          this.$emit("getAllCustomers");
          this.customer_submission = false;
          return;
        }
        this.$toast.error("Echec ! Veuillez réessayer.", {
          position: "top-right",
          timeout: 5000,
        });
      } catch (error) {
        console.log(error);
      }
    },
    isRequired(value) {
      if (!value) {
        return "Ce champ est obligatoire";
      }
    },
    onChange(e) {
      this.professional = e.target.value;
    },
    updateVisibleCustomers() {
      this.visibileCustomers = this.customers;
    },
  },
};
</script>
