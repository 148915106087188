<template>
  <base-loading v-if="isLoading" />
  <div class="container-fluid" v-else>
    <div class="row no-gutters mt-3">
      <div class="col-12">
        <base-breadcrumb :pageName="currentPageName" :storeName="''" />
      </div>
    </div>
    <!---------Filter Stars---------------->
    <div class="d-flex justify-content-between mt-3">
      <div>
        <button
          @click="showModal"
          class="btn btn-primary border border-primary px-2"
        >
          <i class="fas fa-fw fa-plus"></i> Ajouter un client
        </button>
        <base-remove-filter-button
          :rendered="toggle_filter_button"
          customClass="btn btn-info ml-2"
          :all="allCustomers"
        />
      </div>
      <!-- Pagination -->
      <div
        class="row d-flex justify-content-end mr-1"
        v-if="filteredCustomers.length"
      >
        <paginate
          :container-class="'pagination'"
          :page-class="'page-item'"
          v-if="pageCount > 1"
          v-model="page"
          :page-count="pageCount"
          :page-range="3"
          :margin-pages="2"
          :click-handler="paginate"
          prev-text="Précédent"
          next-text="Suivant"
        >
        </paginate>
      </div>
    </div>
    <!-------- Filtter Ends---------------->
    <div class="row justify-content-center">
      <div class="col-12 mt-2">
        <div class="table-card card p-4 shadow-lg">
          <table
            class="table global-table table-striped table-hover table-sm bg-white table-reponsive border-none"
          >
            <thead>
              <tr>
                <app-dropdown
                  v-for="(filterName, i) in filterables"
                  :key="filterName"
                  :searchThrowCustomers="searchThrowCustomers"
                  :focusOnClick="focusOnClick"
                  :filterName="filterName"
                  :customClass="customClasses[i]"
                  :customLabel="customLabels[i]"
                />
              </tr>
            </thead>
            <tbody v-if="filteredCustomers.length">
              <tr v-for="customer in filteredCustomers" :key="customer.id">
                <app-customer-composition :customer="customer" />
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="mt-2">
                <td colspan="12">
                  <base-empty-result />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <base-modal-bootstrap ref="bootstrapModal" title="Ajouter un nouveau client">
    <template v-slot:content>
      <AddCustomer @getAllCustomers="getCustomers"> </AddCustomer>
    </template>
  </base-modal-bootstrap>
</template>
<script>
import Paginate from "vuejs-paginate-next";
import AddCustomer from "@/views/customer/AddCustomer.vue";
import AppDropdown from "@/views/customer/Dropdown";
import AppCustomerComposition from "@/views/customer/CustomerComposition";
import CustomerService from "@/services/customer.service";
const customerService = new CustomerService();

export default {
  components: {
    Paginate,
    AddCustomer,
    AppDropdown,
    AppCustomerComposition,
  },
  data() {
    return {
      filterables: [
        "reference",
        "name",
        "mobile",
        "email",
        "address",
        "city",
        "country",
      ],
      customClasses: [
        "col-1-5",
        "col-1-5 px-2",
        "col-2",
        "col-2",
        "col-2",
        "col-1-5",
        "col-1-5",
      ],
      customLabels: [
        "Référence",
        "Désignation",
        "Mobile",
        "Email",
        "Adresse",
        "Ville",
        "Pays",
      ],
      filteredCustomers: [],
      customersToPaginate: [],
      customers: [],
      countries: [],
      page: 1,
      per_page: 24,
      pageCount: 0,
      isLoading: false,
      currentPageName: "Comptes",
      reference: null,
      name: null,
      mobile: null,
      email: null,
      address: null,
      city: null,
      country: null,
      toggle_filter_button: false,
    };
  },
  created() {
    this.getCustomers();
  },
  methods: {
    focusOnClick(id) {
      setTimeout(() => {
        document.getElementById(id).focus();
      }, 300);
    },
    mouseleave(id) {
      setTimeout(() => {
        $(`#${id}`).dropdown("hide");
      }, 3000);
    },
    async getCustomers() {
      this.isLoading = true;
      try {
        const customers = await customerService.get();
        this.customers = customers.data;
        this.customersToPaginate = customers.data;
        this.pageCount = Math.ceil(this.customers.length / this.per_page);
        this.paginate(this.customers);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    showModal() {
      this.$refs.bootstrapModal.showModal();
    },
    closeModal() {
      this.$refs.bootstrapModal.closeModal();
    },
    paginate(pageNum) {
      this.filteredCustomers = this.customersToPaginate
        .slice((pageNum - 1) * this.per_page)
        .slice(0, this.per_page);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    allCustomers() {
      this.customersToPaginate = this.customers;
      this.page = 1;
      this.pageCount = Math.ceil(
        this.customersToPaginate.length / this.per_page
      );
      this.paginate(this.page);
      this.toggle_filter_button = false;
    },
    searchThrowCustomers(keyword, field) {
      let result = [];
      keyword = this.toLowerCase(keyword);
      this.page = 1;
      if (keyword == "") {
        this.paginate(this.page);
        return;
      }
      switch (field) {
        case "reference":
          result = _.filter(this.customers, (o) =>
            _.includes(this.toLowerCase(o.reference), keyword)
          );
          break;
        case "name":
          result = _.filter(this.customers, (o) =>
            _.includes(this.toLowerCase(o.name), keyword)
          );
          break;
        case "mobile":
          result = _.filter(this.customers, (o) =>
            _.includes(o.mobile, keyword)
          );
          break;
        case "email":
          result = _.filter(this.customers, (o) =>
            _.includes(o.email, keyword)
          );
          break;
        case "address":
          result = _.filter(this.customers, (o) =>
            _.includes(this.toLowerCase(o.billing_address), keyword)
          );
          break;
        case "city":
          result = _.filter(this.customers, (o) =>
            _.includes(this.toLowerCase(o.billing_city), keyword)
          );
          break;
        case "country":
          result = _.filter(this.customers, (o) =>
            _.includes(this.toLowerCase(o.billing_country), keyword)
          );
          break;
        default:
          result = this.customers;
          break;
      }
      this.toggle_filter_button = true;
      this.pageCount = Math.ceil(result.length / this.per_page);
      this.customersToPaginate = result;
      this.paginate(this.page);
    },
    toLowerCase(keyword) {
      if (keyword) return keyword.toLowerCase();
      return keyword;
    },
    setCustomerAsDefault(customer) {
      localStorage.setItem("defaultCustomer", JSON.stringify(customer));
      this.$toast.success("Client par défaut mis à jour", {
        position: "top-right",
        timeout: 5000,
      });
    },
  },
};
</script>
<style scoped>
.dropdwon-sm {
  font-size: small;
}
.card-container .card {
  border-radius: 15px;
}
.client-item:hover {
  border-radius: 15px;
  background-color: rgb(241, 244, 246);
}

.empty-value {
  background-color: darkgray;
}
.col-1-5 {
  max-width: 17% !important;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.page-item:hover {
  cursor: pointer;
}
.page-link:hover {
  /* color: #0056b3; */
  cursor: pointer;
}
.pagination {
  height: 5px !important;
}
.page-item {
  background-color: black !important;;
}
.page-link{
   padding: 1px !important;
}
</style>
