<template>
  <td class="col-1-5" :class="{ 'empty-value': !customer.reference }">
    {{ customer.reference }}
  </td>
  <td class="col-1-5">{{ customer.name }}</td>
  <td class="col-2">{{ customer.mobile }}</td>
  <td class="col-2">{{ customer.email }}</td>
  <td class="col-2">
    <div>{{ customer.billing_address }}</div>
    <div>{{ customer.billing_zipcode }}</div>
  </td>
  <td class="col-1-5">{{ customer.billing_city }}</td>
  <td class="col-1-5">{{ customer.billing_country }}</td>
</template>

<script>
export default {
  name: "customerComposition",
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
};
</script>
