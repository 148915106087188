<template>
  <th :class="customClass" class="p-3">
    <div
      @click="focusOnClick('input-' + filterName)"
      class="dropdown dropdwon-sm"
    >
      <span
        type="button"
        :id="'dropdown-' + filterName"
        data-toggle="dropdown"
        aria-expanded="false"
        class="filter-icon"
      >
        <i class="fas fa-filter text-muted"></i> {{ customLabel }}
      </span>
      <!-- @mouseleave="mouseleave('dropdown-ref')" -->
      <div
        class="dropdown-menu p-3 text-center"
        style="width: 100%;"
        :aria-labelledby="'dropdown-' + filterName"
      >
        <!-- ref="test-1" -->
        <input
          type="text"
          class="form-control"
          :id="'input-' + filterName"
          v-model="model"
          @keyup="searchThrowCustomers(model, filterName)"
        />
      </div>
    </div>
  </th>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    searchThrowCustomers: {
      type: Function,
      required: true,
    },
    focusOnClick: {
      type: Function,
      required: true,
    },
    filterName: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      required: true,
    },
    customLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: null,
    };
  },
};
</script>
<style scoped>
.filter-icon i:hover{
   color: black !important;
}
th{
  font-size: 15px;
}
</style>